.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rs-color-black);
  padding: 32px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.rounded {
  border-radius: 0px 0px 48px 48px;
}

.colored {
  background: linear-gradient(180deg, #552CAE 0%, #6c38a9 100%);
}