.form {}

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rs-color-black);
  padding: 0 32px 32px;
  border-radius: 0px 0px 48px 48px;
  position: sticky;
  top: 91px;
  z-index: 2;
}

.row {
  display: flex;
  background-color: var(--rs-color-black-t95);
  border-radius: 48px;
  padding: 32px;
  gap: 174px;

  background-image: url("../../images/star2.svg");
  background-position: 50% -20%;
  background-size: cover;
  background-repeat: no-repeat;


  &:last-child {
    background: var(--rs-color-black-t90);
  }
}

.participant {
  flex-wrap: wrap;
  column-gap: 174px;
  row-gap: 32px;
}

.col {
  min-width: 370px;
}

.error {
  position: absolute;
  color: var(--rs-color-danger);
}

.el {
  margin-bottom: 48px;

  &:last-child {
    margin-bottom: 0;
  }
}

.date {
  display: flex;
  align-items: end;
  margin-bottom: 56px;

  >div:first-child {
    margin-right: 16px;
  }
}

.label {
  margin-bottom: 16px;
  color: var(--rs-color-white);
}

.input {
  margin-top: 16px;
}

.button {
  margin-top: 8px;
}