.card {
  display: block;
  position: relative;
  width: 392px;
  height: 378px;
  background-color: var(--rs-color-black);
  background-position: center;
  background-size: cover;
  border-radius: 24px;
  text-decoration: none;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--rs-color-black);
  width: 100%;
  border-radius: 0 0 24px 24px;
}

.title {
  color: var(--rs-color-white);
}