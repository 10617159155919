.button {
  margin-right: 16px;
  position: relative;
}

.button:last-child {
  cursor: default;
}

.button:not(:last-child):before {
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  width: 16px;
  height: 1px;
  background-color: rgba(132, 115, 255, 1);
}

.active {
  border-color: rgba(132, 115, 255, 1) !important;
  background-color: rgba(132, 115, 255, 0.3);

  &:hover {
    background-color: rgba(132, 115, 255, 0.5) !important;
  }
}