.success {
  height: 347px;
}

.subheader {
  padding: 0 32px 32px;
  background: linear-gradient(180deg, #6c38a9 0%, #F88 194.19%);
  border-radius: 0px 0px 48px 48px;
  color: var(--rs-color-white);
  position: sticky;
  top: 0;
  z-index: 2;
  top: 91px;
}

.subheader-item {
  margin-bottom: 32px;
  color: var(--rs-color-white);
}

.success {
  border-radius: 48px;
  background-position: center;
  background-size: cover;
  flex: 1 0 auto;
}