.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
}
