.camp {
  background-color: rgba(132, 115, 255, 0.30);
  padding: 32px;
  border-radius: 48px;
}

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rs-color-black);
  padding: 0 32px 32px;
  border-radius: 0px 0px 48px 48px;
  position: sticky;
  top: 0;
  z-index: 2;
  top: 91px;
}

.subheader-block {
  flex: 1 0 33%;
  color: var(--rs-color-white);

  text-align: center;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}

.info {
  display: flex;
  gap: 113px;
}

.title {
  margin-bottom: 16px;
}

.description {
  max-width: 432px;

  ul {
    list-style: disc outside;
    margin-left: 12px;
  }
}

.gallery {
  margin-bottom: 20px;
  overflow: hidden;
}

.images {
  display: flex;
  column-gap: 32px;

  transition: transform 200ms linear;
}

.slided {
  transform: translateX(-408px);
}

.tag {
  margin-right: 8px;
}

.image {
  width: 816px;
  height: 480px;
  border-radius: 24px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  flex: 0 0 auto;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrows {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
