@import '~reset-css';
@import '~@rescui/typography/lib/font-jb-sans-auto.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'JetBrains Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: var(--rs-color-grey-5);
}

.container {
  width: 100%;
  max-width: 1304px;
  margin: auto;
}
