.wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-image: url("../../images/star.svg");
  background-position: 50% -50%;
}

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.title {
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: JetBrains Sans;
  font-size: 120px;
  font-style: normal;
  font-weight: 600;
  line-height: 104px;
  max-width: 1115px;
  background: var(--Gradient1, linear-gradient(180deg, #552CAE 0%, #F88 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 32px;
}

.controls {
  display: flex;

  .button {
    padding-left: 32px;
    padding-right: 32px;
  }
}

