.block {
  border-radius: 48px;
  padding: 32px;
  flex: 1 0 auto;

  &:first-child {
    background: linear-gradient(180deg, #552CAE 0%, #F88 265.53%);
  }

  &:last-child {
    background-color: black;
    background-image: url("../../images/star3.svg");
    background-position: center bottom;
    background-size: cover;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.title {
  margin-bottom: 38px;
}

.item {
  width: 286px;

  .icon {
    fill: var(--rs-color-white);
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
  }
}

.item-title {
  margin-bottom: 8px;
}
