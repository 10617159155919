.catalog {
  background-color: rgba(132, 115, 255, 0.30);
  padding: 32px;
  border-radius: 48px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  position: relative;
}

.haha {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 48px;
  background: rgba(132, 115, 255, 0.30);
  backdrop-filter: blur(8px);
  cursor: pointer;
}

.button {
  margin-right: 8px;
}

.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rs-color-black);
  padding: 0 32px 32px;
  border-radius: 0px 0px 48px 48px;
  position: sticky;
  top: 0;
  z-index: 2;
  top: 91px;
}

.subheader-block {
  flex: 1 0 33%;
  color: var(--rs-color-white);

  text-align: center;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
}
